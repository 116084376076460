/* eslint-disable camelcase */
import { T } from '@deity-io/falcon-i18n';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { Link, useRouteMatch } from 'react-router-dom';
import Breadcrumbs from '../../components/Category/Breadcrumbs/Breadcrumbs';
import useMenuNavbarItems from '../../hooks/useMenuNavbarItems';
import './SalesAndDiscounts.scss';

const ROUTE_QUERY = gql`
  query SalesQuery($url: String!) {
    route(url: $url) {
      ... on CategoryInterface {
        name
      }
    }
  }
`;

const SalesAndDiscounts = () => {
  const navbarItems = useMenuNavbarItems();
  const { path } = useRouteMatch();
  const { data } = useQuery(ROUTE_QUERY, {
    variables: { url: path },
  });

  const groups = useMemo(() => {
    if (!navbarItems) {
      return null;
    }

    const getSection = item => {
      const firstItem = item.elements && item.elements[0];

      if (!firstItem || firstItem.type !== 'row') {
        return null;
      }

      const section = firstItem.elements[1];

      if (!section || !section.elements) {
        return null;
      }

      // get section item with matching path
      return section.elements.find(({ elements: [{ url }] }) => {
        return url.endsWith(path);
      });
    };

    return navbarItems.reduce((newList, { id, title, elements = [] }) => {
      const items = elements.reduce((newItemList, item) => {
        const section = getSection(item);

        if (!section) {
          return newItemList;
        }

        const [{ url }, { custom_src }] = section.elements;
        const imageUrl = custom_src.startsWith('/') ? `https://cdn2.emmezeta.hr${custom_src}` : custom_src;

        newItemList.push({
          id: item.id,
          title: item.title,
          url,
          imageUrl,
        });

        return newItemList;
      }, []);

      if (items.length === 0) {
        return newList;
      }

      newList.push({ id, title, items });

      return newList;
    }, []);
  }, [navbarItems, path]);

  if (!navbarItems) {
    return null;
  }

  return (
    <div className="sales-discounts">
      {data && <Breadcrumbs routeData={data.route} isCategory />}

      <h2 className="sales-discounts__title">{data && data.route.name}</h2>

      {groups.map(group => (
        <div key={group.id} className="sales-discounts__group">
          <h3>{group.title}</h3>
          <div className="sales-discounts__group-list">
            {group.items.map(item => (
              <Link key={item.id} className="sales-discounts__item" to={item.url}>
                <img src={item.imageUrl} alt="" />
                <p className="sales-discounts__subtitle">{item.title}</p>
                <p className="sales-discounts__float">
                  <T id="action" />
                </p>
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(SalesAndDiscounts);
